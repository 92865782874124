import {toggleLikeForPost} from '../utils/api';
import { useState } from 'react';
import { FaReply } from 'react-icons/fa'
import { GrEdit } from 'react-icons/gr'
import { ImHeart } from 'react-icons/im'
import { BsHeart } from 'react-icons/bs'
import './MyPost.css';

export default function MyPost({postPk, postSk, postHeader, postBody, postFooter, postLikes}){

    const currentUser='useridA';

    const [likes, setLikes] = useState(postLikes);

    const getHeartType=(likes, currentUser)=>{
        const f = likes && likes.find(  (liker) => liker.split("#")[0] === currentUser );
        if (f)
            return <ImHeart className="has-text-danger"/>;
        return <BsHeart className="has-text-danger"/>;
    }

    return <>
        <div className="post">
            {postHeader && <div className="card-header">
                <div className='card-header-icon'/>
                <div className="card-header-title">
                    {postHeader}
                </div>
            </div>}

            {postBody && <div className="card-content" key='keybody'>
                {postBody}
            </div>}

            <div className="card-footer columns is-multiline p-1" key='keyfooter'>
                <div className="container">
                    <nav className="navbar">
                        <div className="navbar-start">
                            <button className="navbar-item" onClick={(e)=>{
                                toggleLikeForPost(postPk, postSk, currentUser, (e)=>{setLikes(e)});
                            }}>
                                {getHeartType(likes, currentUser)}&nbsp;{likes ? likes.length : 0}
                            </button>
                        </div>
                        <div className="navbar-end">
                            <a className="navbar-item" href=".">Edit&nbsp;<GrEdit/></a>
                            <a className="navbar-item" href=".">Reply&nbsp;<FaReply/></a>
                        </div>
                    </nav>
                </div>

                {postFooter}
            </div>

        </div>

    </>
}