import imgPoolDeck from './img/pool-deck.jpg';

import imgRampart01 from './img/20191123_145240 Rampart-02.jpg';
import imgRampart02 from './img/Rampart-03.jpg';
import imgRampart03 from './img/20191123_143332 Rampart-01.png';
import imgRampart04 from './img/Rampart-04.jpg';

import imgPlayaAliciaSunset from './img/20220402_185331 Playa Alicia Sunset.jpg';

import imgCentralPark01 from './img/CentralPark-01.jpg';


import imgArrival01 from './img/20200303_103649 Arrival Rainbow.jpg';
import imgArrival02 from './img/20221114_131123 Arrival Wing.jpg';
import imgArrival03 from './img/20221114_152245 Arrival Airport Exit.jpg';
import imgArrival04 from './img/20221114_154232 Arrival Cheers.png';
import imgArrival05 from './img/20221115_172323 Playa Dorada East.jpg';
import imgArrival06 from './img/20221115_172323 Playa Dorada West.jpg';

import imgVillaKitchen from './img/20220406_103051 Villa-Kitchen.jpg';
import imgVillaSitting from './img/20220406_103055 Villa Sitting Area.jpg';
import imgVillaOutdoorLiving from './img/20220406_103231 Villa OutdoorLivingArea.jpg';
import imgVillaFrontDoor from './img/20220406_104237 Villa FrontDoor.jpg';
import imgVillaPoolFromDining from './img/20220406_104315 Villa Pool from dining area.jpg';
import imgVillaIndoorLiving from './img/20220406_104324 Villa IndoorLivingArea.jpg';
import imgCastaways from './img/castaways-restaurant-casa-linda-2.png';

import imgVilla00 from './img/Villa00.jpg';
import imgVilla01 from './img/Villa01.jpg';
import imgVilla02 from './img/Villa02.jpg';
import imgVilla03 from './img/Villa03.jpg';
import imgVilla04 from './img/Villa04.jpg';
import imgVilla05 from './img/Villa05.jpg';
import imgVilla06 from './img/Villa06.jpg';


import imgSosua01 from './img/20220403_185046 Sosua01.jpg';
import imgSosua02 from './img/20220403_185051 Sosua02.jpg';
import imgSosua03 from './img/20220403_185835 Sosua03.jpg';
import imgSosua04 from './img/20220403_185848 Sosua04.jpg';
import imgSosua05 from './img/20220403_190833 Sosua05.jpg';
import imgSosua06 from './img/20220403_191404 Sosua06.jpg';

const t={};
t.en={
    HomeSlides:[
        {
            slideSrc: imgArrival02,
            slideLegend: <>Puerto Plata from the air.</>
        },
        {
            slideSrc: imgArrival03,
            slideLegend: <>Emerging from the airport.</>
        },
        {
            slideSrc: imgArrival04,
            slideLegend: <>Welcome, drink!</>
        },
        {
            slideSrc: imgVillaFrontDoor,
            slideLegend: <>Front door entrance.</>
        },
        {
            slideSrc: imgVillaKitchen,
            slideLegend: <>The modern kitchen is spacious and well appointed.</>
        },
        {
            slideSrc: imgCastaways,
            slideLegend: <>On-site Restaurant</>
        },
        {
            slideSrc: imgVillaSitting,
            slideLegend: <>Sitting Area</>
        },
        {
            slideSrc: imgVillaOutdoorLiving,
            slideLegend: <>Outdoor living area.</>
        },
        {
            slideSrc: imgVillaPoolFromDining,
            slideLegend: <>View of the pool, from the dining area.</>
        },
        {
            slideSrc: imgVillaIndoorLiving,
            slideLegend: <>Living room.</>
        },
        {
            slideSrc: imgPlayaAliciaSunset,
            slideLegend: <>Sunset at The Waterfront Restaurant at Playa Alicia</>
        },

    ],
    Home: [
        {
            cardHeader: <>Vacation Villa</>,
            cardBodies:[
                <>There are plenty of advantages of renting a private villa for your next vacation. Here are just a few...</>,
                <><p className='subtitle'>Creature comforts</p> Get ready to really settle in and relax in the tranquility of your own private villa. Children love that they are not confined to a hotel room. There’s nothing better than breakfast in your P.J.s!</>,
                <><p className='subtitle'>Privacy</p> If hanging at the resort pool with hundreds of strangers isn’t your thing, a villa allows you and your family/friends to enjoy your vacation with the seclusion and intimacy that only a private home would allow.</>,
                <><p className='subtitle'> Perfect for small groups</p> An ideal meeting place for a family get together or a corporate retreat, villas allow each party to have their own private sleeping quarters as well as the ability to gather and socialize in the common areas of the villa.</>,
                <><p className='subtitle'>Services</p> Maid service, personal chef, chauffer and a concierge can all be at your beck and call while at the same time offering discretion for those times when you don’t want to be disturbed.</>,
                <><p className='subtitle'>Surprising value</p> Some might think renting a villa is pricey, but when you compare the price tag to that of a luxury hotel, you will often find that it saves you money. Particular value is found when you compare a larger villa vs. the cost of multiple hotel rooms for groups.</>,
            ],
            cardSlides: [
                { cardSrc: imgVilla00},
                { cardSrc: imgPoolDeck,},
                { cardSrc: imgVilla01,},
                { cardSrc: imgVilla02, cardLegend: "Guest House"},
                { cardSrc: imgVilla03,},
                { cardSrc: imgVilla04,},
                { cardSrc: imgVilla05,},
                { cardSrc: imgVilla06,},
            ],

        },
        // {
        //     cardHeader:<>What We Do</>,
        //     cardBody:"Glamarette Partners offers consulting services and research that combine economic analysis with public and legislative outreach.",
        //     cardImage: imgPoolDeck,
        //     cardImageAlt: "What we do"
        // },
        // {
        //     cardHeader:<>Reservations</>,
        //     cardBody:"Book now to secure your vacation villa and to avoid disappointment later. ",
        //     cardImage: imgPlayaAliciaSunset
        // },
        {
            cardHeader: <>Arriving in the DR</>,
            cardBodies: [
                <>
                    <p className="has-text-weight-bold">Visa/Entry process</p>
                    <div className="list">

                        <li>fingerprints upon 1st entry</li>
                        <li>dog sniffers upon exit</li>
                        <li>amenities at POP airport</li>
                        <li>online exit visa</li>
                        {/* form (time saver at airport, must complete return form within 48 hours of return flight/not in advance) */}
                    </div>
                </>,
                <>
                    <p className="has-text-weight-bold">Transportation to/from airport</p>
                    <div className="list">
                        <li>porters everywhere</li>
                        <li>taxis, uber, prearranged coach bus</li>
                    </div>
                </>,
                <>
                    <p className="has-text-weight-bold">General</p>
                    <div className="list">
                        <li>how to tip in the DR</li>
                        <li>negotiating on retail items</li>
                        <li>dealing with beach vendors</li>
                    </div>
                </>,
                <>
                    <p className="has-text-weight-bold">More Tips</p>
                    <div className="list">
                        <li>hiring a chef</li>
                        <li>shopping for groceries</li>
                        <li>guided excursions</li>
                        <li>private day trips</li>
                    </div>

                </>

                ],
            cardSlides: [
                { cardSrc: imgArrival01, cardLegend: <>Rainbow when flying in to Puerto Plata</>},
                { cardSrc: imgArrival02, cardLegend: <>Approaching Puerto Plata</>},
                { cardSrc: imgArrival03, cardLegend: <>Emerging from the airport</>},
                { cardSrc: imgArrival04, cardLegend: <>Arrival Mission Successful, Cheers!!!</>},
                { cardSrc: imgArrival05, cardLegend: <>Playa Dorada Beach, East</>},
                { cardSrc: imgArrival06, cardLegend: <>Playa Dorada Beach, West</>}
            ]
        },
        {
            cardHeader: <>Places To See</>,
            cardBodies: [
                "The Fortaleza San Felipe is a historic Spanish fortress located in the north of Dominican Republic in the province of Puerto Plata.",
                "It is also known as El Morro de San Felipe and was used to protect the City of Puerto Plata from pirates and corsairs.",
                null,
                null,
                "Also known as Independence Square, Central Park was constructed as a tribute to general Luperón, who offered this beautiful park to the city of Puerto Plata when it was the Dominican capital.",
                "Playa Dorada is an exclusive beach in a gated community of several resorts.",
                "Playa Sosua offers picturesque vistas where the eye captures far more than a camera ever could.",
                null,
                "Playa Sosua offers beach activites including watersports, fishing and catamaran tours.",
                null,
                null,
                "The sunsets are a simply amazing way to wrap up your day.",
            ],
            cardSlides: [
                { cardSrc: imgRampart01, cardLegend: "Watchtower at the fort."},
                { cardSrc: imgRampart02, cardLegend: "Ocean view from the ramparts."},
                { cardSrc: imgRampart03},
                { cardSrc: imgRampart04},
                { cardSrc: imgCentralPark01, cardLegend: "Central Park"},
                { cardSrc: imgArrival05, cardLegend: "Facing East at Playa Dorada"},
                { cardSrc: imgSosua01},
                { cardSrc: imgSosua02},
                { cardSrc: imgSosua03},
                { cardSrc: imgSosua04},
                { cardSrc: imgSosua05},
                { cardSrc: imgSosua06},




            ]
        },
        // {
        //     cardHeader: <>Things To Do</>,
        //     cardBody:<>
        //     Visa/entry process
        //     - online form (time saver at airport, must complete return form within X hours of return flight/not in advance, fingerprints upon 1st entry, dog sniffers upon exit, vaccination/Covid updates, amenities at POP)

        //    Transportation to/from airport
        //    - porters everywhere
        //    - taxis, uber, prearranged coach bus

        //    General
        //    - how to tip in the DR
        //    - how to negotiate on retail items
        //    - how to deal with beach vendors
        //    </>,
        //     cardImage: imgRampart02
        // },
        // {
        //     cardHeader: <>Places To Stay</>,
        //     cardBody:<>
        //         Considerations for Air B&B vs All inclusive (location, distance to amenities, transportation, length of stay, number of ppl in party)
        //         Air B&B
        //         Casa Linda
        //         Emotions
        //    </>,
        //     cardImage: imgPoolDeck
        // },


    ],

    Team: [
        {
            cardHeader:"Our Team",
            cardBody:<>
            <p>
                Guided by the principles of sustainability and fairness, our team of experts search the globe for economic opportunities.
            </p>
            <p> Our team of industry experts continue to deliver wins, time after time.
            </p>
            </>
          }
    ],

    About: [
        {
            cardHeader:"About Us",
            cardBody:"Glamarette Partners is an international trade and economic consulting firm with a global outlook and reach through a network of international economists."
        }
    ]
};

export default t;
