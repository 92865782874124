import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel"
import { useState } from "react";
export default function MyCard({cardHeader, cardBodies, cardSlides, cardFooter, idx}){
    const [idxCurrentCard, setIdxCurrentCard]=useState(0);
    const getCardBody=(idxCurrentCard)=>{
        let lookBackFrom = idxCurrentCard;
        if (lookBackFrom >= (cardBodies.length-1))
            lookBackFrom = cardBodies.length-1;
        while (lookBackFrom > 0){
            if (cardBodies[lookBackFrom] !== null) break;
            lookBackFrom--;
        };
        return lookBackFrom;
    }

    return <>
        <div className="card p-2">
            {cardHeader && <div className="card-header">
                <div className="card-header-title">
                    {cardHeader}
                </div>
            </div>}

            {cardSlides && <div className="card-image image">

                <Carousel showArrows={true}
                    onChange={(idx, item)=>{
                        setIdxCurrentCard(idx);
                        // console.log("onChange: %o, %o", idx, idxCurrentCard)
                    }}
                    // onClickItem={console.log("onClickItem")}
                    // onClickThumb={console.log("onClickThumb")}
                    autoPlay={true}
                    interval={4500 + (idx % 2)*750}
                    infiniteLoop={true}
                    showThumbs={false}
                    showStatus={ cardSlides.length>1 }
                    showIndicators={ cardSlides.length>1 }
                >
                    {
                        cardSlides && cardSlides.map( (cardSlide, idx)=>{
                            return (
                                <div key={idx}>
                                    <img src={cardSlide.cardSrc} alt="Visual" />
                                    { cardSlide.cardLegend && <p className="legend">{cardSlide.cardLegend}</p>}
                                </div>
                            );
                        })
                    }
                </Carousel>
            </div>}


            {cardBodies && ( cardBodies[ getCardBody(idxCurrentCard) ]) && <div className="card-content" key={'keybody'+idxCurrentCard}>
                {cardBodies[ getCardBody(idxCurrentCard)]}
            </div>}

            {cardFooter && <div className="card-footer" key={'keyfooter'+idxCurrentCard}>
                {cardFooter}
            </div>}

        </div>

    </>
}