import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import './Home.css';
import MyCard from '../MyCard/MyCard'
import t from '../i18n';
import { showWindow } from "../utils/debug";

export default function Home(){
	showWindow("Home(): ")
    return <>
		<div className="hero-body">
			<div className="container">
				<div className="columns is-vcentered is-justify-content-space-evenly p-5">
					<div className="column is-9">
						{/* <img src={imgPoolDeck} alt="My placeholder" /> */}
						<Carousel showArrows={true}
							// onChange={console.log("onChange")}
							// onClickItem={console.log("onClickItem")}
							// onClickThumb={console.log("onClickThumb")}
							autoPlay={true}
							interval={3750}
							infiniteLoop={true}
							showThumbs={false}
						>
							{t.en.HomeSlides.map( (obj, idx)=>{
								return (<div key={idx}>
									<img src={obj.slideSrc} alt="Visual" />
									<p className="legend">{obj.slideLegend}</p>
								</div>)
							})}
						</Carousel>
					</div>
				</div>
			</div>
		</div>

		<div className="columns is-multiline">
			{
				t.en.Home.map( (obj, idx)=>{
					return (
						<div className="column is-4" key={idx}>
							<MyCard
								cardHeader={obj.cardHeader}
								cardSlides={obj.cardSlides}
								cardBodies={obj.cardBodies}
								cardFooter={obj.cardFooter}
								idx={idx}
							/>
						</div>
					);
				})
			}
		</div>
   </>
}