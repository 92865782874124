import {FaFacebookF, FaTwitter, FaInstagram, FaDribbble } from 'react-icons/fa';
import { CgCrown as BsTelephoneFill } from 'react-icons/cg'
//import { BsTelephoneFill } from 'react-icons/bs'

import {GiPalmTree as MdEmail} from 'react-icons/gi'
//import { MdEmail } from 'react-icons/md'
import imgLogo from '../img/gp-alpha.png'
import "./Header.css";
import { NavLink, Outlet } from "react-router-dom";

export default function Header(){
    return <div id="glam-header">
        <section className="hero is-vcentered is-fullheight-with-navbar block">
			<div className="hero-head">
				<nav className="navbar is-fixed-top">
                    <div className="navbar-brand is-size-4">
                        <div className="navbar-item">
                            <div className="media">
                                <div className="media-left">
                                    <figure className="image is-48x48">
                                        <img src={imgLogo} alt="Glamarette Logo"/>
                                    </figure>
                                </div>
                                <div className="media-content">
                                    <p className="title is-4">Glamarette Partners</p>
                                    <p className="subtitle is-6">Luxury Dominican Villa Vacation</p>
                                </div>
                            </div>

                        </div>
                        <div id="navbar-burger" className="navbar-burger" data-target="navbarMenuHeroB"
                            onClick={(e) =>{
                                window.document.getElementById("navbarMenuHeroB").classList.toggle("is-active");
                                window.document.getElementById("navbar-burger").classList.toggle("is-active");
                            }}
                        >
                            <span/><span/><span/>
                        </div>
                    </div>
                    <div id="navbarMenuHeroB" className="navbar-menu"
                        onClick={(e) =>{
                                window.document.getElementById("navbarMenuHeroB").classList.remove("is-active");
                                // console.log("click in navbar menu");
                                window.document.getElementById("navbar-burger").classList.remove("is-active");
                        }}
                        >
                        <div className="navbar-end">
                            <NavLink to="/Home" className={(n)=>( n.isActive ? "navbar-item is-active" : 'navbar-item')}>Home</NavLink>
                            <NavLink to="/About" className={(n)=>( n.isActive ? "navbar-item is-active" : 'navbar-item')}>About</NavLink>
                            <NavLink to="/Contact" className={(n)=>( n.isActive ? "navbar-item is-active" : 'navbar-item')}>Contact</NavLink>
                            <NavLink to="/dummy" className={(n)=>( n.isActive ? "navbar-item is-active" : 'navbar-item')}>dummy</NavLink>
                        </div>
                    </div>
				</nav>
            </div>

			<div className="hero-body">
				<div className="container">
					<Outlet/>
				</div>
			</div>

			<div className="hero-footer">
				<div className="container">
				<nav className="navbar card">
					<div className="navbar-start container">
						<a className="navbar-item" href="/#"><BsTelephoneFill/>&nbsp;123-456-7890</a>
						<a className="navbar-item" href="/#"><MdEmail/>&nbsp;emailsample@email.com</a>
						<div className="navbar-end">
							<div className="navbar-item social-media">
								<a href="/#"><FaFacebookF/>&nbsp;</a>
								<a href="/#"><FaTwitter/>&nbsp;</a>
								<a href="/#"><FaInstagram/>&nbsp;</a>
								<a href="/#"><FaDribbble/>&nbsp;</a>
							 </div>
				 		</div>
					</div>
				</nav>

				</div>
			</div>
		</section>
    </div>
}
