import {useState, useCallback } from 'react';

const urlPostsApiGateway = "https://clvw3z0z8f.execute-api.us-east-1.amazonaws.com/";
const urlLikesApiGateway = "https://cdm0qssjf9.execute-api.us-east-1.amazonaws.com/";

const DB_POST_PK = "posts-partition";
const DB_POST_SK = "posts-sort-key";
const DB_POST_COMMENT = "comment";
const DB_POST_LIKES = "likes";


const toggleLikeForPost = async (postPk, postSk, likerUserId, fnCallback)=>{
    const response = await fetch(urlLikesApiGateway+`likes`, {
        method: 'PATCH',
        body: JSON.stringify({
            postPk,
            postSk,
            likerUserId
        })
    });
    const data = await response.json();
    fnCallback(data.likes);
    return data.likes;
}




const getPostsForPage= async (postPk)=>{
    if (!postPk) return;

    if (window.timeoutGetAllPosts != null) {
        return JSON.parse(window.sessionStorage.getAllPosts); //throttled
    }
    window.timeoutGetAllPosts = setTimeout(
        ()=>{
            window.timeoutGetAllPosts = null;
        }, 10000
    )
    const response = await fetch(urlPostsApiGateway+`items/${encodeURIComponent(postPk)}`);
    const data = await response.json();
    window.sessionStorage.setItem('getAllPosts', JSON.stringify(data));
    return data;
}

const useGetPostsForPage = (postPk) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    const execute = async () => {
      try {
        setIsLoading(true);
        const posts = await getPostsForPage(postPk);
        setData(posts);
        return posts;
      }
      catch (e)
      {
        setError(e);
        throw e;
      }
      finally{
        setIsLoading(false);
      }
    };

    return {
      isLoading,
      error,
      data,
      execute: useCallback(execute, [postPk]), // to avoid infinite calls when inside a `useEffect`
    };
  };

export {useGetPostsForPage, getPostsForPage, toggleLikeForPost, DB_POST_PK, DB_POST_SK, DB_POST_COMMENT, DB_POST_LIKES};