import {useEffect} from 'react';
import {useGetPostsForPage, DB_POST_COMMENT, DB_POST_LIKES, DB_POST_PK, DB_POST_SK} from '../utils/api';
import "./About.css";
import MyPost from '../MyPost/MyPost'
import { showWindow } from '../utils/debug';

export default function About(){
    showWindow("About(): ");
    const {isLoading, data, execute} = useGetPostsForPage("userid0#entrydate0");
    useEffect(() => {
        try {
            execute();
        }
        catch(e){
            console.log("ERR retrieving all posts");
        }
    }, [execute]);

    const getChildren=(post, level)=>{
        if (!post) return;
        // console.log("post: %o", post);
        const postId = post[DB_POST_SK].split("##")[1];
        level++;

        const allResponses = data.filter( (p, idx)=> (p[DB_POST_SK].split("##")[0] === postId) && (p[DB_POST_SK].split("##")[1] !== postId) );
        // console.log("allResponses: %o", allResponses);

        const postTree = allResponses.map( (p, idx) => { return (
            <div className={`column is-full`} key={idx}
                style={{width:`${100 - (level+1)}%`}}
            >
                <MyPost
                    postPk={p[DB_POST_PK]}
                    postSk={p[DB_POST_SK]}
                    postHeader={p[DB_POST_SK]}
                    postBody={[p[DB_POST_COMMENT]]}
                    idx={idx}
                    postFooter={getChildren(p, level)}
                    postLikes={p[DB_POST_LIKES]}
                />
            </div>
        )});

        // console.log("postTree: %o", postTree);
        return postTree;
    }

    return <>
    <div className="about hero-body">
        <div className="container">
            <div className="columns is-multiline ">
                {data && <div className="column is-full">
                    <MyPost
                        postPk={data[0][DB_POST_PK]}
                        postSk={data[0][DB_POST_SK]}

                        postHeader={data[0][DB_POST_SK]}
                        postBody={data[0][DB_POST_COMMENT]}
                        postFooter={getChildren(data[0], 0)}
                        postLikes={data[0][DB_POST_LIKES]}
                    />
                </div>}
            </div>
        </div>
        <div className={"ajax-spinner-lightbox " + (isLoading ? "loading" : "") } ></div>
    </div>
    </>
}