// import logo from './logo.svg';
import './App.css';
import { /*BrowserRouter as Router,*/ Routes, Route, Navigate } from "react-router-dom";
import Header from "./Header/Header"
import Home from "./Home/Home"
import About from "./About/About"
import Contact from "./Contact/Contact"
import { showWindow } from './utils/debug';


function App() {
  console.log("you made it to App(): %o", window.location);
  console.log("you made it to App(): ", window.location.href);
  return (
    <div className="App">
        {/* <Router basename={process.env.PUBLIC_URL}> */}
          <Routes>
            {showWindow("App A:")}
            <Route path="*" element={<Header/>}>
              <Route exact path="home" element={<Home />} />
              <Route exact path="about" element={<About />} />
              <Route exact path="contact" element={<Contact />} />
              <Route path="*" element={<Navigate to="home" replace />} />
            </Route>
            <Route path="*" element={<Navigate to="home" replace />} />
          </Routes>
        {/* </Router> */}
    </div>
  )
}
export default App;
