import React from 'react';
import { BrowserRouter as Router} from "react-router-dom";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bulma/css/bulma.min.css';
import { showWindow } from './utils/debug';

const root = ReactDOM.createRoot(document.getElementById('root'));
/*try this with BrowserRouter */
// <Router basename={process.env.PUBLIC_URL}></Router>
const baseName = (window.location.host==="react347.gitlab.io" ? "/web-glamarette" : "");
console.log("baseName: %o", baseName);

root.render(
  <Router basename={baseName}>

  {/* <React.StrictMode> */}
  {showWindow("T16 index.js: ")}
    <App />
  {/* </React.StrictMode> */}
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
